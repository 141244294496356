<template>
  <div>
    <iframe src="https://blackbird.litzscore.com/fantasy-game/" frameborder="0"
     height="100%" width="100%" title="circle ji console" class="iframe-style"></iframe>
  </div>
</template>
<style lang="scss">
.iframe-style {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: size(60);
  left: size(80);
  right: 0;
  bottom: 0;
}
</style>
